<div class="modal-header">
  <h1 class="modal-title fs-5">{{ getActionTitle() }}</h1>
  <button (click)="modal.dismiss()"
          aria-label="Close"
          class="btn-close"
          data-bs-dismiss="modal"
          type="button"></button>
</div>
<div class="modal-body">
  <p>{{ getActionMessage() }}</p>
</div>
<div class="modal-footer">
  <button mat-button
          (click)="modal.dismiss()">
    {{ getActionNegativeButton() }}
  </button>
  <button mat-flat-button
          (click)="modal.close('submitAction')"
          type="submit">
    {{ getActionPositiveButton() }}
  </button>
</div>
