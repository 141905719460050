import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Village} from "../../../core/VillageAdapter";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TaskStatus} from "../../../core/TaskAdapter";
import {TranslateService} from "@ngx-translate/core";

export type Filter =
  {
    workerId: number | null,
    villageId: number | null,
    workType: number | null,
    status: TaskStatus | null,
    startedOnFrom: number | null,
    startedOnTo: number | null,
    completedOnFrom: number | null,
    completedOnTo: number | null
  } | undefined;

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  filterForm: FormGroup;

  @Input()
  applyFilter: Filter;

  @Input()
  workers: { id: number, name: string }[];

  @Input()
  villages: Village[]

  @Input()
  workTypes: { id: number, name: string }[];

  statuses: { id: number, name: string }[];

  @Output()
  filter = new EventEmitter<Filter>

  constructor(private formBuilder: FormBuilder,
              private translate: TranslateService) {
  }

  onFilterApply() {
    this.filter.next(this.filterForm.value);
  }

  onFilterClear() {
    this.filterForm.reset();
    this.filter.next(undefined);
  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      villageId: [this.applyFilter?.villageId ?? null],
      workerId: [this.applyFilter?.workerId ?? null],
      workType: [this.applyFilter?.workType ?? null],
      status: [this.applyFilter?.status ?? null],
      startedOnFrom: [this.applyFilter?.startedOnFrom ?? null],
      startedOnTo: [this.applyFilter?.startedOnTo ?? null],
      completedOnFrom: [this.applyFilter?.completedOnFrom ?? null],
      completedOnTo: [this.applyFilter?.completedOnTo ?? null],
    });
    this.statuses = [
      {id: TaskStatus.WIP, name: this.translate.instant('task.status.WIP')},
      {id: TaskStatus.COMPLETED, name: this.translate.instant('task.status.COMPLETED')},
      {id: TaskStatus.CANCELLED, name: this.translate.instant('task.status.CANCELLED')},
      {id: TaskStatus.QA, name: this.translate.instant('task.status.QA')},
      {id: TaskStatus.REDO, name: this.translate.instant('task.status.REDO')},
      {id: TaskStatus.APPROVED, name: this.translate.instant('task.status.APPROVED')},
      {id: TaskStatus.AUTO_APPROVED, name: this.translate.instant('task.status.AUTO_APPROVED')},
      {id: TaskStatus.PENDING, name: this.translate.instant('task.status.PENDING')},
      {id: TaskStatus.UNASSIGNED, name: this.translate.instant('task.status.UNASSIGNED')},
    ].sort((a, b) => a.name.localeCompare(b.name));
  }
}
