import {Injectable} from '@angular/core';
import {Village, VillageAdapter} from "../core/VillageAdapter";
import {HttpClient} from "@angular/common/http";
import {Chunk, ChunkAdapter, ChunkType} from "../core/ChunkAdapter";
import {SampleAction, WaterSource, WaterSourceAdapter} from "../core/WaterSourceAdapter";
import {
  BehaviorSubject,
  finalize,
  forkJoin,
  interval,
  merge,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  tap,
  throttle
} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {House, HouseAdapter} from "../core/HouseAdapter";
import {AnimalShelter, AnimalShelterAdapter} from "../core/AnimalShelterAdapter";
import * as turf from "@turf/turf";
import {feature, Feature, FeatureCollection} from "@turf/turf";
import {calculatePercentage} from "../common/math";
import {VillageStats, VillageStatsAdapter} from "../core/VillageStatsAdapter";
import {TaskService} from "../task/task.service";
import {Task} from "../core/TaskAdapter";

@Injectable({
  providedIn: 'root'
})
export class VillageService {
  private villagesSub: Subscription;
  private surveyedAreaSub: Subscription;
  private _villages = new BehaviorSubject<Village[]>([]);
  private _tasks = new BehaviorSubject<Task[]>([]);
  private _chunks = new BehaviorSubject<Chunk[]>([]);
  private _waterSources = new BehaviorSubject<WaterSource[]>([]);
  private _houses = new BehaviorSubject<House[]>([]);
  private _animalShelters = new BehaviorSubject<AnimalShelter[]>([]);
  private _surveyedArea = new BehaviorSubject<Map<number, Feature>>(new Map());
  private _taskSurveyedArea = new BehaviorSubject<Feature>(turf.multiPolygon([]));
  private _villagesStats = new BehaviorSubject<VillageStats[]>([]);
  private destroy$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private taskService: TaskService,
    private adapter: VillageAdapter,
    private chunkAdapter: ChunkAdapter,
    private waterSourceAdapter: WaterSourceAdapter,
    private houseAdapter: HouseAdapter,
    private animalShelterAdapter: AnimalShelterAdapter,
    private villageStatsAdapter: VillageStatsAdapter
  ) {
  }

  cancelRequest() {
    this.destroy$.next();
  }

  clear() {
    this._tasks.next([]);
    this._villages.next([]);
    this._chunks.next([]);
    this._waterSources.next([]);
    this._houses.next([]);
    this._animalShelters.next([]);
    this._surveyedArea.next(new Map());
    this._taskSurveyedArea.next(turf.multiPolygon([]));
    this._villagesStats.next([]);
  }

  get villagesStats$(): Observable<VillageStats[]> {
    return this._villagesStats.asObservable();
  }

  get villages$(): Observable<Village[]> {
    return this._villages.asObservable();
  }

  get chunks$(): Observable<Chunk[]> {
    return this._chunks.asObservable();
  }

  get waterSources$(): Observable<WaterSource[]> {
    return this._waterSources.asObservable();
  }

  get houses$(): Observable<House[]> {
    return this._houses.asObservable();
  }

  get animalShelters$(): Observable<AnimalShelter[]> {
    return this._animalShelters.asObservable();
  }

  get surveyedArea$(): Observable<Feature[]> {
    return this._surveyedArea.pipe(
      map((surveyedArea) => {
        return [...surveyedArea.keys()].reduce((acc: Feature[], key: number) => {
          acc.push(surveyedArea.get(key)!);

          return acc;
        }, [])
      }),
    );
  }

  get taskSurveyedArea$(): Observable<Feature | undefined> {
    return this._taskSurveyedArea.asObservable();
  }

  getVillagesStats(filter: {
    limitDays?: number,
    start?: Date,
    end?: Date
  } = {limitDays: 90}): void {
    let getVillagesStatsApi = `${environment.BASE_URL}/villages/stats?`;

    if (filter.limitDays) {
      getVillagesStatsApi += `limitDays=${filter.limitDays}`;
    } else if (filter.start || filter.end) {
      if (filter.start) {
        getVillagesStatsApi += `start=${filter.start.getTime()}`;
      }
      if (filter.end) {
        getVillagesStatsApi += `&end=${filter.end.getTime()}`;
      }
    }

    this.http.get(getVillagesStatsApi).pipe(
      takeUntil(this.destroy$),
      map((data: any) => data.map((item: any) => this.villageStatsAdapter.adapt(item))),
      tap((villagesStats: VillageStats[]) => {
        this._villagesStats.next(villagesStats);
      })
    ).subscribe();
  }

  create(name: string, border: FeatureCollection): Observable<Village> {
    const createVillageApi = `${environment.BASE_URL}/villages`;

    return this.http.post(createVillageApi, {
      name: name,
      border: border
    }).pipe(
      map((item: any) => this.adapter.adapt(item)),
      tap((village: Village) => {
        let villages = this._villages.value;

        if (!villages) {
          villages = [];
        }

        villages.push(village);

        this._villages.next(villages);

      })
    );
  }

  getVillages() {
    this.villagesSub?.unsubscribe();
    this.surveyedAreaSub?.unsubscribe();

    const getVillagesApi = `${environment.BASE_URL}/campaigns/{campaignId}?include=villages`;

    this.clear();

    this.surveyedAreaSub = interval(5 * 60 * 1000).pipe(
      startWith(0),
      tap(() => {
      }),
      switchMap(() => this.getSurveyedArea().pipe(
        tap((surveyedArea: FeatureCollection) => {
          const result = new Map<number, Feature>;

          if (surveyedArea) {
            surveyedArea.features.forEach((item: Feature) => {
              const villageId = item.properties!["villageId"];
              result.set(villageId, feature(item.geometry));
              this.updateSurveyedPercentage(villageId, feature(item.geometry));
            });
          }

          this._surveyedArea.next(result);
        })
      )),
    ).subscribe();

    this.villagesSub = this.http.get(getVillagesApi).pipe(
      map((data: any) => data.villages.map((item: any) => this.adapter.adapt(item))),
      tap((villages: Village[]) => {
        villages.map((village: Village) => {
          this.updateSurveyedPercentage(village.id, this._surveyedArea.value.get(village.id));
        });
        this._villages.next(villages);
      }),
      switchMap((villages: Village[]) => {
        if (villages.length === 0) {
          return of([]);
        }

        return merge(
          this._animalShelters$(),
          this._waterSources$(villages),
          ...this._houses$(villages),
        ).pipe(
          finalize(() => {
            forkJoin(this._chunks$(villages))
              .pipe(
                throttle(() => interval(2000))
              )
              .subscribe();
          })
        );
      }),
    ).subscribe();

    this.taskService.getTasks();
  }

  updateVillage(villageId: number, data: any): Observable<void> {
    const updateVillageApi = `${environment.BASE_URL}/villages/${villageId}`;

    return this.http.patch(updateVillageApi, data).pipe(
      takeUntil(this.destroy$),
      tap((item: any) => {
        const villages = this._villages.value;
        const index = villages.findIndex((village: Village) => village.id === villageId);
        villages[index].name = item.name;
        this._villages.next([...villages]);
      })
    );
  }

  getSurveyedAreaByTask(taskId: number): Observable<Feature | undefined> {
    const getSurveyedAreaApi = `${environment.BASE_URL}/survey?taskId=${taskId}`;

    return this.http.get(getSurveyedAreaApi).pipe(
      takeUntil(this.destroy$),
      map((data: any) => {
        if (data && data.geometry) {
          return data as Feature;
        } else {
          return undefined;
        }
      })
    ).pipe(tap((surveyedArea) => {
      if (surveyedArea) {
        this._taskSurveyedArea.next(surveyedArea);
      } else {
        this.clearSurveyedAreaByTask();
      }
      }));
  }

  clearSurveyedAreaByTask() {
    this._taskSurveyedArea.next(turf.multiPolygon([]));
  }

  private _animalShelters$(): Observable<AnimalShelter[]> {
    return this.getAnimalShelters().pipe(
      tap((animalShelters: AnimalShelter[]) => {
        this._animalShelters.next(this._animalShelters.value.concat(animalShelters));
      }));
  }

  private _chunks$(villages: Village[]): Observable<Chunk[]>[] {
    return villages.map((village: Village, index) => this.getChunks(village.id).pipe(
      tap((chunks: Chunk[]) => {
        this.updateChunks(chunks);
        this.updateChunksCount(villages, index, chunks);
      })
    ));
  }

  private _waterSources$(villages: Village[]): Observable<WaterSource[]> {
    const allWaterSources$ = villages.map((village: Village) => {
      return this.getWaterSources(village.id);
    });

    return merge(...allWaterSources$).pipe(
      tap((waterSources: WaterSource[]) => {
            this._waterSources.next(this._waterSources.value.concat(waterSources));
        this.updateWaterSources(villages, waterSources);
      })
    );
  }

  private _houses$(villages: Village[]): Observable<House[]>[] {
    return villages.map((village: Village) =>
      this.getHouses(village.id).pipe(
        tap((houses: House[]) => {
          this._houses.next(this._houses.value.concat(houses));
        })
      )
    );
  }

  private getChunks(villageId: number): Observable<Chunk[]> {
    const getChunksApi = `${environment.BASE_URL}/villages/${villageId}?include=chunks`;
    this._chunks.next([]);

    return this.http.get(getChunksApi).pipe(
      takeUntil(this.destroy$),
      map((data: any) => data.chunks.map((item: any) => this.chunkAdapter.adapt(item))),
      shareReplay(1)
    );
  }

  private getWaterSources(villageId: number): Observable<WaterSource[]> {
    const getWaterSourcesApi = `${environment.BASE_URL}/villages/${villageId}?include=water_sources`;
    this._waterSources.next([]);

    return this.http.get(getWaterSourcesApi).pipe(
      takeUntil(this.destroy$),
      map((data: any) => data.waterSources.map((item: any) => this.waterSourceAdapter.adapt(item))),
      shareReplay(1)
    );
  }

  private getHouses(villageId: number): Observable<House[]> {
    const getHousesApi = `${environment.OLD_BASE_URL}/houses/{campaignId}/village/${villageId}`;
    this._houses.next([]);

    return this.http.get(getHousesApi).pipe(
      takeUntil(this.destroy$),
      map((data: any) => data.map((item: any) => this.houseAdapter.adapt(item))),
      shareReplay(1)
    );
  }

  private getAnimalShelters(): Observable<AnimalShelter[]> {
    const getAnimalSheltersApi = `${environment.OLD_BASE_URL}/animalShelters/{campaignId}`;
    this._animalShelters.next([]);

    return this.http.get(getAnimalSheltersApi).pipe(
      takeUntil(this.destroy$),
      map((data: any) => data.map((item: any) => this.animalShelterAdapter.adapt(item))),
      shareReplay(1)
    );
  }

  private updateChunks(chunks: Chunk[]) {
    this._chunks.next(this._chunks.value.concat(chunks));
  }

  private getSurveyedArea(): Observable<FeatureCollection> {
    const getSurveyedAreaApi = `${environment.BASE_URL}/survey?campaignId={campaignId}`;

    return this.http.get(getSurveyedAreaApi).pipe(
      takeUntil(this.destroy$),
      map((data: any) => {
        return data;
      }),
      shareReplay(1)
    );
  }

  private updateChunksCount(villages: Village[], villageIndex: number, chunks: Chunk[]) {
    const coreChunksCount = chunks.filter((chunk: Chunk) => chunk.type === ChunkType.CORE).length;
    const beltChunksCount = chunks.filter((chunk: Chunk) => chunk.type === ChunkType.BELT).length;
    const village = villages[villageIndex];
    village.coreChunksCount = coreChunksCount;
    village.beltChunksCount = beltChunksCount;

    this._villages.next(villages);
  }

  private updateWaterSources(villages: Village[], waterSources: WaterSource[]) {
    if (waterSources.length > 0) {
      const treatedWaterSources = waterSources.filter((waterSource: WaterSource) => waterSource.isTreated);
      const sampledWaterSources = waterSources.filter((waterSource: WaterSource) => waterSource.isSampled);
      const sampledPositiveWaterSources = sampledWaterSources.filter((waterSource: WaterSource) =>
        waterSource.actions.some((action: any) => action instanceof SampleAction
          && (action.anopheles > 0 || action.culex > 0 || action.pupae > 0)));
      const issuedWaterSources = waterSources.filter((waterSource: WaterSource) => waterSource.isIssued);
      const totalWaterSources = waterSources.length;
      const village = villages.find((village: Village) => village.id === waterSources[0].villageId)!;

      village.waterSourcesCount = totalWaterSources;
      village.treatedWaterSourcesCount = treatedWaterSources.length;
      village.sampledWaterSourcesCount = sampledWaterSources.length;
      village.sampledNegativeWaterSourcesCount = village.sampledWaterSourcesCount - sampledPositiveWaterSources.length;
      village.sampledPositiveWaterSourcesCount = sampledPositiveWaterSources.length;
      village.issuedWaterSourcesCount = issuedWaterSources.length;

      this._villages.next(villages);
    }
  }

  private updateSurveyedPercentage(villageId: number, surveyed?: Feature) {
    const villages = this._villages.value;
    const surveyedArea = surveyed ? turf.area(surveyed) / 1000000 : 0;

    const village = villages.find((village: Village) => village.id === villageId);

    if (village) {
      village.surveyedPercentage = calculatePercentage(village.area, surveyedArea);
    }
  }

  createVillagesWithFile(file: File): Observable<Village[]> {
    const createUrl = `${environment.BASE_URL}/villages/upload`;
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(createUrl, formData).pipe(
      map((item: any) => item.map((village: any) => this.adapter.adapt(village))),
      tap((newVillages: Village[]) => {
        const villages = this._villages.getValue();
        villages.push(...newVillages);
        this._villages.next(villages);
      })
    );
  }
}
