import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {Campaign} from "../core/CampaignAdapter";
import {Village} from "../core/VillageAdapter";
import {Chunk} from "../core/ChunkAdapter";
import {WaterSource} from "../core/WaterSourceAdapter";
import {VillageService} from "../village/village.service";
import {debounceTime, Observable, Subscription} from "rxjs";
import {CampaignService} from "../campaign/campaign.service";
import {SidebarService} from "./sidebar/sidebar.service";
import {Router} from "@angular/router";
import {NgbTooltipConfig} from "@ng-bootstrap/ng-bootstrap";
import {SpinnerService} from "../spinner/spinner.service";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy, AfterViewInit {
  loading$: Observable<boolean>;
  campaigns$: Observable<Campaign[]>;
  villages$: Observable<Village[]>;
  isSideNavOpened = false;
  sidebarLayout: string;
  villages: Village[];
  chunks: Chunk[];
  waterSources: WaterSource[];
  private villagesSub: Subscription;
  private chunksSub: Subscription;
  private waterSourcesSub: Subscription;

  constructor(
    private router: Router,
    private villageService: VillageService,
    campaignService: CampaignService,
    private sidebarService: SidebarService,
    private spinnerService: SpinnerService,
    config: NgbTooltipConfig
  ) {
    config.placement = 'top-start';
    config.triggers = 'hover';
    this.campaigns$ = campaignService.campaigns$;
    this.villages$ = villageService.villages$;
  }

  ngOnDestroy(): void {
    this.villagesSub.unsubscribe();
    this.chunksSub.unsubscribe();
    this.waterSourcesSub.unsubscribe();
  }

  ngOnInit(): void {
    this.loading$ = this.spinnerService.loading$;
    this.villagesSub = this.villageService.villages$
      .subscribe((villages) => {
        this.villages = villages;
      });
    this.chunksSub = this.villageService.chunks$
      .pipe(debounceTime(3000))
      .subscribe((chunks) => {
        this.chunks = chunks;
      });
    this.waterSourcesSub = this.villageService.waterSources$
      .subscribe((waterSources) => {
        this.waterSources = waterSources;
      });
  }

  ngAfterViewInit(): void {
    this.sidebarService.sidenavState$.subscribe((openedContent) => {
      if (openedContent) {
        this.sidebarLayout = openedContent;
        this.isSideNavOpened = true;
      } else {
        this.isSideNavOpened = false;
      }
    });
  }

  onSearchSelected(event: { bbox: number[] | undefined; center: number[] | undefined }) {
    this.router.navigate([''], {
      queryParams: {
        bbox: event.bbox?.join(','),
        center: event.center?.join(','),
      }
    });
  }
}
