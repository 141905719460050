import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {User, UserAdapter} from "../core/UserAdapter";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable, tap, throwError} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _users = new BehaviorSubject<User[]>([]);

  constructor(
    private http: HttpClient,
    private adapter: UserAdapter) {
  }

  get users$(): Observable<User[]> {
    return this._users.asObservable();
  }

  getUsers(): void {
    const getUserApi = `${environment.BASE_URL}/users`;

    this.http.get(getUserApi).pipe(
      map((data: any) => data.map((item: any) => this.adapter.adapt(item))),
    ).subscribe((users: User[]) => this._users.next(users));
  }

  updateUser(userId: number, data: any): Observable<User> {
    const updateUrl = `${environment.BASE_URL}/users/${userId}`;

    return this.http.put(updateUrl, data).pipe(
      map((item: any) => this.adapter.adapt(item)),
      tap((user: User) => {
        const users = this._users.getValue();
        const index = users.findIndex((user) => user.id === userId);
        users[index] = user;
        this._users.next(users);
      })
    );
  }

  createUser(data: any): Observable<User> {
    const createUrl = `${environment.BASE_URL}/users`;

    return this.http.post(createUrl, data).pipe(
      map((item: any) => this.adapter.adapt(item)),
      tap((user: User) => {
        const users = this._users.getValue();
        users.push(user);
        this._users.next(users);
      })
    )
  }

  createUserWithFile(file: File): Observable<User[]> {
    const createUrl = `${environment.BASE_URL}/users/upload`;
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(createUrl, formData).pipe(
      map((item: any) => item.map((user: any) => this.adapter.adapt(user))),
      tap((newUsers: User[]) => {
        const users = this._users.getValue();
        users.push(...newUsers);
        this._users.next(users);
      })
    );
  }

  deleteUser(id: number): Observable<any> {
    const deleteUrl = `${environment.BASE_URL}/users/${id}`;

    return this.http.delete(deleteUrl).pipe(
      tap(() => {
        const users = this._users.getValue();
        const index = users.findIndex((user) => user.id === id);

        if (index !== -1) {
          users.splice(index, 1);
          this._users.next(users);
        }
      }),
      catchError(() => {
        return throwError(() => new Error('Failed to delete user.'));
      })
    );
  }
}
