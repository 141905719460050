import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {BehaviorSubject, catchError, Observable, Subscription, tap} from "rxjs";
import {Task, TaskAdapter, TaskStatus} from "../core/TaskAdapter";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import Swal from "sweetalert2";
import {Campaign} from "../core/CampaignAdapter";
import fp from 'lodash/fp'

const { flow, groupBy, mapValues }  = fp;

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private _tasks: BehaviorSubject<Task[] | undefined> = new BehaviorSubject<Task[] | undefined>(undefined);
  private tasksSub: Subscription;

  constructor(private http: HttpClient,
              private adapter: TaskAdapter) {
  }

  get tasks$(): Observable<Task[] | undefined> {
    return this._tasks
      .asObservable()
      .pipe(map((tasks: Task[] | undefined) => {
        if (tasks) {
          const notCancelledTasks = tasks
            ?.filter((task: Task) => task.status !== TaskStatus.CANCELLED);

          const sortedTasksByWorkTypeAndVillageId = flow(
            groupBy('workType'),
            mapValues(
              flow(groupBy('villageId'),
                mapValues((tasks: Task[]) => tasks.sort((a, b) => compareTask(a, b))))
            )
          )(notCancelledTasks);

          return notCancelledTasks
            .map((currentTask: Task) => {
              // @ts-ignore
              currentTask.isLastTask = currentTask.id === sortedTasksByWorkTypeAndVillageId[currentTask.workType][currentTask.villageId][0].id;

              return currentTask;
            })
        } else {
          return undefined;
        }
      }));
  }

  createTask(campaign: Campaign, data: any): Observable<Task> {
    const createTaskUrl = `${environment.BASE_URL}/tasks`;
    return this.http.post(createTaskUrl, data, {
      headers: {
        'Campaign-Id': `${campaign.id}`
      }
    }).pipe(
      map((item: any) => this.adapter.adapt(item)),
      tap((task: Task) => {
        let tasks = this._tasks.getValue();

        if (!tasks) {
          tasks = [];
        }

        tasks.push(task);

        this._tasks.next(tasks);
      })
    )
  }

  getTasks(useCache: boolean = true) {
    this.tasksSub?.unsubscribe();
    this._tasks.next(undefined);
    const getTasksUrl = `${environment.BASE_URL}/tasks`;
    let headers: any = {};

    if (!useCache) {
      headers['Cache-Control'] = 'must-revalidate';
    }

    this.tasksSub = this.http.get(getTasksUrl, {
      headers: headers
    }).pipe(
      map((data: any) => data.map((item: any) => this.adapter.adapt(item))),
      tap((tasks: Task[]) => {
        this._tasks.next(tasks);
      }),
    ).subscribe({
      error: (err) => {
        Swal.fire({
          text: err.message,
          icon: "error"
        });
      }
    })
  }

  assignUser(taskId: number, userId: number): Observable<Task> {
    const assignUserUrl = `${environment.BASE_URL}/tasks/assign`;

    return this.http.post(assignUserUrl, {
      taskId,
      userId,
    }).pipe(
      map((item: any) => this.adapter.adapt(item)),
      tap((task: Task) => {
        const tasks = this._tasks.getValue()!;
        const index = tasks.findIndex((task) => task.id === taskId);

        if (index !== -1) {
          tasks[index] = task;
        } else {
          tasks.push(task);
        }

        this._tasks.next(tasks);
      })
    )
  }

  updateTaskStatus(taskId: number, status: TaskStatus): Observable<Task> {
    const updateAssignmentStatusUrl = `${environment.BASE_URL}/tasks`;

    return this.http.patch(updateAssignmentStatusUrl, {
      taskId: taskId,
      status
    }).pipe(
      map((data: any) => this.adapter.adapt(data)),
      tap((changedTask: Task) => {
        const tasks = this._tasks.getValue()!;
        const index = tasks.findIndex((task) => task.id === changedTask.id);
        tasks[index] = changedTask;
        this._tasks.next(tasks);
      }),
      catchError((err: HttpErrorResponse) => {
          throw new Error(err.message);
      })
    )
  }
}

const priorityOrder: TaskStatus[] = [
  TaskStatus.WIP,
  TaskStatus.UNASSIGNED,
  TaskStatus.PENDING,
  TaskStatus.APPROVED,
  TaskStatus.COMPLETED,
  TaskStatus.REDO
];

function compareTask(taskA: Task, taskB: Task): number {
  if ((taskA.status === TaskStatus.APPROVED || taskA.status === TaskStatus.COMPLETED)
    && (taskB.status === TaskStatus.APPROVED || taskB.status === TaskStatus.COMPLETED)) {
    return (taskB.summary!.completedOn ?? 0) - (taskA.summary!.completedOn ?? 0);
  }

  const indexA = priorityOrder.indexOf(taskA.status);
  const indexB = priorityOrder.indexOf(taskB.status);

  // If both statuses are in the priorityOrder, sort based on their order
  if (indexA !== -1 && indexB !== -1) {
    if (indexA !== indexB) {
      return indexA - indexB;
    } else {
      return taskB.id - taskA.id;
    }
  }

  // If only one status is in the priorityOrder, prioritize it
  if (indexA !== -1) return -1;
  if (indexB !== -1) return 1;

  // If neither status is in the priorityOrder, sort by their natural order
  return taskA.status - taskB.status;
}
