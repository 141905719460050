import {Adapter} from "./Adapter";
import {Injectable} from "@angular/core";

export class VillageStats {
  readonly id: number;
  readonly name: string;
  readonly villagesCount: number;
  readonly area: number;
  readonly surveyed: {
    readonly area: number;
    readonly percentage: number;
  };
  readonly waterSourcesCount: number;
  readonly deletedWaterSourcesCount: number;
  readonly treatedWaterSources: {
    readonly now: number,
    readonly total: number
  };
  readonly issuedWaterSources: {
    readonly now: number,
    readonly total: number
  };
  readonly sampledWaterSources: {
    readonly now: {
      readonly count: number,
      readonly percentage: number
    };
    readonly total: {
      readonly count: number,
      readonly percentage: number
    };
    readonly totalPositive: {
      readonly count: number,
      readonly percentage: number
    };
    readonly nowPositive: {
      readonly count: number,
      readonly percentage: number
    };
  };
  readonly closestSurveyTaskId: number | undefined;
  readonly closestSampleTaskId: number | undefined;
  readonly closestTreatTaskId: number | undefined;
  closestSurveyTask?: {
    readonly workType: string,
    readonly taskStatus: string,
    readonly userName: string | undefined,
  };
  closestTreatTask?: {
    readonly workType: string,
    readonly taskStatus: string,
    readonly userName: string | undefined,
  };
  closestSampleTask?: {
    readonly workType: string,
    readonly taskStatus: string,
    readonly userName: string | undefined,
  };
  treatAndIssueProgressChart: {
    date: number,
    newWaterSources: number,
    treatedWaterSources: number,
    issuedWaterSources: number
  }[];
  orangeFlag?: number;
  yellowFlag?: number;
  purpleFlag?: number;
  totalFlag?: number;

  constructor(
    id: number,
    name: string,
    villagesCount: number,
    area: number,
    surveyed: {
      readonly area: number,
      readonly percentage: number
    },
    waterSourcesCount: number,
    deletedWaterSourcesCount: number,
    treatedWaterSources: {
      readonly now: number,
      readonly total: number
    },
    issuedWaterSources: {
      readonly now: number,
      readonly total: number
    },
    sampledWaterSources: {
      readonly now: {
        readonly count: number,
        readonly percentage: number
      };
      readonly total: {
        readonly count: number,
        readonly percentage: number
      };
      readonly totalPositive: {
        readonly count: number,
        readonly percentage: number
      };
      readonly nowPositive: {
        readonly count: number,
        readonly percentage: number
      };
    },
    closestSurveyTaskId: number | undefined,
    closestSampleTaskId: number | undefined,
    closestTreatTaskId: number | undefined,
    treatAndIssueProgressChart: {
      date: number,
      newWaterSources: number,
      treatedWaterSources: number,
      issuedWaterSources: number
    }[],
    orangeFlag?: number,
    yellowFlag?: number,
    purpleFlag?: number,
    totalFlag?: number
  ) {
    this.id = id;
    this.name = name;
    this.villagesCount = villagesCount;
    this.area = area;
    this.surveyed = surveyed;
    this.waterSourcesCount = waterSourcesCount;
    this.deletedWaterSourcesCount = deletedWaterSourcesCount;
    this.treatedWaterSources = treatedWaterSources;
    this.issuedWaterSources = issuedWaterSources;
    this.sampledWaterSources = sampledWaterSources;
    this.closestSurveyTaskId = closestSurveyTaskId;
    this.closestSampleTaskId = closestSampleTaskId;
    this.closestTreatTaskId = closestTreatTaskId;
    this.treatAndIssueProgressChart = treatAndIssueProgressChart;
    this.orangeFlag = orangeFlag;
    this.yellowFlag = yellowFlag;
    this.purpleFlag = purpleFlag;
    this.totalFlag = totalFlag;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VillageStatsAdapter implements Adapter<VillageStats> {
  adapt(item: any): VillageStats {
    return new VillageStats(
      item.id,
      item.name,
      item.villagesCount,
      item.area,
      {
        area: item.surveyed.area,
        percentage: item.surveyed.percentage
      },
      item.waterSourcesCount,
      item.deletedWaterSourcesCount,
      {
        now: item.treatedWaterSources.now,
        total: item.treatedWaterSources.total
      },
      {
        now: item.issuedWaterSources.now,
        total: item.issuedWaterSources.total
      },
      {
        now: item.sampledWaterSources.now,
        total: item.sampledWaterSources.total,
        totalPositive: {
          count: item.sampledWaterSources.totalPositive.count,
          percentage: item.sampledWaterSources.totalPositive.percentage
        },
        nowPositive: {
          count: item.sampledWaterSources.nowPositive.count,
          percentage: item.sampledWaterSources.nowPositive.percentage
        },
      },
      item.closestSurveyTaskId,
      item.closestSampleTaskId,
      item.closestTreatTaskId,
      item.treatAndIssueProgressChart.map((chartItem: any) => {
        return {
          date: chartItem.date,
          newWaterSources: chartItem.new,
          treatedWaterSources: chartItem.treated,
          issuedWaterSources: chartItem.issued
        }
      })
    );
  }
}
